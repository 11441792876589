import { Page } from '@aimmo/model';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  AddWorkspaceParam,
  AddWorkspaceResult,
  InviteUserParam,
  MemberDetail,
  MembersItem,
  MembersParam,
  UpdateWorkspaceParam,
  UsageDailyForAzureItem,
  UsageDailyForAzureParam,
  UsageDailyItem,
  UsageDailyParam,
  Workspace,
  WorkspaceDetail,
  WorkspaceKey,
  WorkspaceKeyForAzure, WorkspaceRole
} from 'aimmo-core2/app/shared/models/workspace.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceApiService {
  private httpClient = inject(HttpClient);

  public fetchOneForAzure(): Observable<Workspace> {
    return this.httpClient.get<Workspace>('/workspace');
  }

  public usageDaily(workspaceId: string, type: string, param: UsageDailyParam): Observable<UsageDailyItem[]> {
    return this.httpClient.get<UsageDailyItem[]>(`/workspaces/${workspaceId}/usages/${type}/daily`, { params: param as any });
  }

  public usageDailyForAzure(param: UsageDailyForAzureParam): Observable<UsageDailyForAzureItem[]> {
    return this.httpClient.get<UsageDailyForAzureItem[]>('/workspaces/usage/daily', { params: param as any });
  }

  public members(workspaceId: string, param: MembersParam): Observable<Page<MembersItem>> {
    return this.httpClient.get<Page<MembersItem>>(`/workspaces/${workspaceId}/members`, { params: param as any });
  }

  public membersForAzure(param: MembersParam): Observable<Page<MembersItem>> {
    return this.httpClient.get<Page<MembersItem>>('/workspaces/members', { params: param as any });
  }

  public memberDetail(workspaceId: string, userId: string): Observable<MemberDetail> {
    return this.httpClient.get<MemberDetail>(`/workspaces/${workspaceId}/members/${userId}`);
  }

  public memberDetailForAzure(userId: string): Observable<MemberDetail> {
    return this.httpClient.get<MemberDetail>(`/workspaces/members/${userId}`);
  }

  public keys(workspaceId: string): Observable<WorkspaceKey[]> {
    return this.httpClient.get<WorkspaceKey[]>(`/workspaces/${workspaceId}/keys`);
  }

  public role(workspaceId: string): Observable<WorkspaceRole> {
    return this.httpClient.get<WorkspaceRole>(`/workspaces/${workspaceId}/role`);
  }

  public keysForAzure(): Observable<WorkspaceKeyForAzure> {
    return this.httpClient.get<WorkspaceKeyForAzure>('/workspaces/keys');
  }

  public workspaces(): Observable<Workspace[]> {
    return this.httpClient.get<Workspace[]>('/workspaces');
  }

  public workspaceDetail(workspaceId: string): Observable<WorkspaceDetail> {
    return this.httpClient.get<WorkspaceDetail>(`/workspaces/${workspaceId}`);
  }

  public updateWorkspace(workspaceId: string, param: UpdateWorkspaceParam): Observable<void> {
    return this.httpClient.put<void>(`/workspaces/${workspaceId}`, param);
  }

  public leaveWorkspace(workspaceId: string, userId: string): Observable<void> {
    return this.httpClient.delete<void>(`/workspaces/${workspaceId}/members/${userId}`);
  }

  public addWorkspace(param: AddWorkspaceParam): Observable<AddWorkspaceResult> {
    return this.httpClient.post<AddWorkspaceResult>('/workspaces', param);
  }

  public inviteUser(workspaceId: string, param: InviteUserParam): Observable<void> {
    return this.httpClient.post<void>(`/workspaces/${workspaceId}/invite`, param);
  }

  public activateKey(workspaceId: string, keyId: string): Observable<WorkspaceKey> {
    return this.httpClient.post<WorkspaceKey>(`/workspaces/${workspaceId}/keys/${keyId}/activate`, {});
  }

  public deactivateKey(workspaceId: string, keyId: string): Observable<WorkspaceKey> {
    return this.httpClient.post<WorkspaceKey>(`/workspaces/${workspaceId}/keys/${keyId}/deactivate`, {});
  }

  public reissueKey(workspaceId: string, keyId: string): Observable<WorkspaceKey> {
    return this.httpClient.post<WorkspaceKey>(`/workspaces/${workspaceId}/keys/${keyId}/reissue`, {});
  }

  public removeMember(workspaceId: string, userId: string): Observable<void> {
    return this.httpClient.delete<void>(`/workspaces/${workspaceId}/members/${userId}`);
  }
}
